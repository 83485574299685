import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "",
    component: () => import("@/layouts/HomeLayout.vue"),
    children : [
        {path: '', component: () => import("@/pages/app/Home.vue")},
        {path: '/about', component: () => import("@/pages/app/About.vue")},
        {path: '/reviews', component: () => import("@/pages/app/Reviews.vue")},
        {path: '/contact', component: () => import("@/pages/app/Contact.vue")},
    ]
  },
  {
    path: "/admin",
    meta: {
        requiresAuth: true
    },
    component: () => import("@/layouts/HomeLayout.vue"),
    children : [
        {path: '', component: () => import("@/pages/admin/Admin.vue")},
    ]
  },
  {
  path: "/admin/login",
  name: "",
  component: () => import("@/pages/admin/Login.vue"),
  },
  // {
  //   path: "/admin/register",
  //   name: "",
  //   component: () => import("@/pages/admin/Register.vue"),
  // },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import('@/pages/404.vue'),
    },  
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
});

export default router;
