<template v-if="data">
<div class="spacing-top">
  <div v-if="title" class="max-width m-auto">
    <h2 class="title mb-2 " >{{title}}</h2>
    <underline class="mb-4"/>
  </div>
  <slot />
</div>
</template>
<script>
export default {
  props:['title', 'data'],
methods: {
},
}
</script>