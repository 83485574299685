<template>
  <div class="is-fixed">
    <div class="is-flex">
      <div class="dropdown is-hoverable">
        <div class="dropdown-trigger">
          <button
            class="button is-rounded button is-small"
            aria-haspopup="true"
            aria-controls="dropdown-menu4"
          >
            <span class="mr-4">{{ this.$store.state.lang ? this.$store.state.lang : $i18n.locale }}</span>
            <span class="pl-2 pr-2 icon has-icon-right is-small">
              <i
                class="fas fa-angle-down"
                aria-hidden="true"
              ></i>
            </span>
          </button>
        </div>
        <div
          class="dropdown-menu"
          id="dropdown-menu4"
          role="menu"
          style="width: 75px"
        >
          <div class="dropdown-content">
            <div class="dropdown-item">
              <a
                class="has-text-black"
                v-for="locale in locales"
                :key="locale"
                href="#"
                @click="switchLocale(locale)"
                :class="
                  locale == this.$store.state.lang
                    ? 'is-active has-text-primary'
                    : ''
                "
              >
                <p>{{ locale }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- <a v-for="locale in locales" :key="locale" @click="switchLocale(locale)" class="navbar-item" :class="locale == this.$store.state.lang ?'is-active' : '' " >
        <p>{{locale}}</p>
    </a> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },

  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$store.commit("SET_LANG", locale);
        this.$i18n.locale = locale;
      }
    },
  },
};
</script>

<style scoped>
li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}
</style>

