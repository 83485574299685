export default {
  "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent OÜ"])},
  "brand_name_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent"])},
  "brand_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bringing you closer to nature!"])},
  "brand_desc_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheap caravan rental aimed to help you enjoy the best camping trip"])},
  "nav_option_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "nav_option_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "nav_option_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "nav_option_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "hero_tag_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping"])},
  "hero_tag_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacuum"])},
  "hero_tag_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauna"])},
  "text_gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "text_our_rentals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our rentals"])},
  "text_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "contact_us_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And have the vacation you deserve!"])},
  "home_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! to Jessy rent OÜ"])},
  "home_about_subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your vacation camper awaits you! Estonia has beautiful forests and views."])},
  "home_about_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our camper we guarantee that you will  be comfortable, warm & cozy during your whole trip."])},
  "home_about_qoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping is not a date; it’s an endurance test. If you can survive camping with someone, you should marry them on the way home."])},
  "home_about_qoute_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yvonne Prinz"])},
  "home_camper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camper highlights"])},
  "home_camper_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your vacation complete with our camper."])},
  "home_camper_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even the best vacation can be ruined by bad sleep or not being able to cook."])},
  "home_camper_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For that we have taken care of all of those problems and provided these options.."])},
  "home_camper_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfort options"])},
  "home_cards_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we have to offer"])},
  "home_reviews_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client reviews"])},
  "home_reviews_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not convinced? see what past customers experienced."])},
  "home_reviews_anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous"])},
  "about_animation_fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "about_animation_word_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent OÜ"])},
  "about_animation_word_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaana Põlts"])},
  "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm Jaana.  I am the secretary-accountant in the seafarers' union."])},
  "about_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To some extent active in one village society and also in the board of an apartment association."])},
  "about_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year I also joined Naiskodukaitse."])},
  "about_body_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have lived most of my life in Harju County.I grew up in a village close to the city, where there was moderate freedom and more or less clean air, but not enough silence and privacy."])},
  "about_body_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's why from time to time my family and I longed to go further and travel to a more natural place."])},
  "about_body_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has always been a pleasure to rest under a pine forest on the shores of a lake or by the sea."])},
  "about_body_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sometimes we also rented a caravan.It was still nice!"])},
  "about_body_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then the idea arose to buy a caravan.What I personally appreciate most is the dream that is mysteriously good in Solifer!"])},
  "about_body_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the autumn of 2021, we moved to Järva County and there is plenty of nature and privacy here, which is why I often don 't go on an adventure with the cottage myself.  So it seemed wise to share your friend with others."])},
  "about_body_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That 's when Jessy Rent OÜ was born, which we are trying to develop gradually, and in the future we hope to offer more for rent."])},
  "footer_follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
  "footer_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
  "footer_place_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a review"])},
  "review_form_label_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "review_form_label_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "review_form_label_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of rental"])},
  "review_form_label_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate Jessy Rent?"])},
  "review_form_placeholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "review_form_placeholder_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was your experience?"])},
  "review_form_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit review"])},
  "review_form_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews will be verified before being published."])},
  "availability_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking availability"])}
}