import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
state: {
    isLoggedIn: false,
    isProgress: false,
    lang: "eng",
    user: null,
  },

mutations: {
    SET_PROGRESS(state, payload) {
        state.isProgress = payload
    },
    
    SET_LANG(state, payload) {
        state.lang = payload
    },

    SET_USER(state, payload) {
        state.user = payload
        state.isLoggedIn = true
    },

    LOGOUT(state) {
        state.user = null
        state.isLoggedIn = false
    }
},
actions: {
    
},
getters: {

},
plugins: [createPersistedState()],
});

