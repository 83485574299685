import { createApp } from 'vue';
import App from './App.vue';


import Router from './router';
import Firebase from '../firebase';
import store from './store';
import i18n from './i18n';
import Notifications from '@kyvg/vue3-notification';
import Container from "./components/utilities/Container.vue";
import InputBox from './components/utilities/InputBox';
import Underline from './components/utilities/Underline';
import localeSwitcher from './components/LocaleSwitcher';
import HoverCard from "./components/utilities/HoverCard.vue";
require('@/assets/scss/main.scss');
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBas8QECQC3s8lHGdV-ENML97I5HrKc2FI",
  authDomain: "jessy-rent.firebaseapp.com",
  projectId: "jessy-rent",
  storageBucket: "jessy-rent.appspot.com",
  messagingSenderId: "69871672626",
  appId: "1:69871672626:web:5e566e2f24529ffa690b2b",
  measurementId: "G-JB817M5FY6"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(firebaseApp)
const app = createApp(App)

app.component('hover-card', HoverCard);
app.component('container', Container);
app.component('input-box', InputBox);
app.component('underline', Underline);
app.component('locale-switcher', localeSwitcher);
app.use(i18n);
app.use(Router);
app.use(store);
app.use(Notifications)
app.use(Firebase)
app.use(auth)
app.use(db)
location.hash = ""

app.mount('#app');
export {auth}
