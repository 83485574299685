<template>
  <div class="devider"></div>
</template>

<style lang="scss">
  .devider{
    width: 5rem;
    height: 0.5rem;
    background-color: var(--primary);
    }
  
</style>