export default {
  "brand_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent OÜ"])},
  "brand_name_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent"])},
  "brand_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toome su loodusele lähemale!"])},
  "brand_desc_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odav haagissuvila rent, mille eesmärk on aidata teil nautida parimat telkimisreisi!"])},
  "nav_option_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaleht"])},
  "nav_option_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meist"])},
  "nav_option_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside"])},
  "nav_option_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "hero_tag_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haagissuvila"])},
  "hero_tag_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekstiilipesur"])},
  "hero_tag_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telksaun"])},
  "text_gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerii"])},
  "text_our_rentals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our rentals"])},
  "text_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võta meiega ühendust"])},
  "contact_us_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ning koge seda puhkust, mida sa väärid"])},
  "home_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tere tulemast Jessy rent OÜ lehele"])},
  "home_about_subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haagissuvila Sinu mõnusaks puhkuseks looduses ootab! Eestis on imeilusad metsad ja vaated."])},
  "home_about_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie haagissuvilas on Sul mugav, soe ja kodune terve reisi ajal."])},
  "home_about_qoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camping is not a date; it’s an endurance test. If you can survive camping with someone, you should marry them on the way home."])},
  "home_about_qoute_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yvonne Prinz"])},
  "home_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tere tulemast Jessy rent OÜ lehele!"])},
  "home_camper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haagissuvila parimad mugavused:"])},
  "home_camper_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda oma väljasõit täiuslikuks meie ratastel koduga."])},
  "home_camper_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halb uni või toidu valmistamise võimaluse puudumine võib ära rikkuda ka kõige täiuslikuma puhkuse."])},
  "home_camper_description_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sellepärast oleme hoolitsenud selle eest, et Sul oleks võimalik kasutada neid mugavusi:"])},
  "home_camper_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valikud mugavuseks"])},
  "home_cards_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mida meie pakume"])},
  "home_reviews_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientide tagasiside"])},
  "home_reviews_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole meis kindel? Vaata, mida eelmised kliendid arvavad."])},
  "home_reviews_anonymous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonüümne"])},
  "about_animation_fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meist"])},
  "about_animation_word_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessy Rent OÜ"])},
  "about_animation_word_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaana Põlts"])},
  "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mina olen Jaana. Olen meremeeste ametiühingu sekretär-raamatupidaja."])},
  "about_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mingil määral olen tegev ühes külaseltsis ja ka korteriühistu juhatuses."])},
  "about_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022. aastal liitusin ka Naiskodukaitsega."])},
  "about_body_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen suurema osa oma elust elanud Harjumaal. Kasvasin üles linnalähedases külas, kus oli mõõdukas vabadus ja enam-vähem puhas õhk, aga vähe vaikust ja privaatsust."])},
  "about_body_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seetõttu ihkasime aeg-ajalt perega minna kaugemale ja reisida mõnda looduslikumasse kohta."])},
  "about_body_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati on olnud meeldiv puhata männimetsa all, järve kaldal või mere ääres."])},
  "about_body_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahel rentisime ka haagissuvilat. See oli ikka tore!"])},
  "about_body_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siis tekkis mõte osta päris enda haagissuvila. Mina isiklikultnaudin kõige rohkem und, mis on Soliferis müstiliselt hea!"])},
  "about_body_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021.aasta sügisel kolisime Järvamaale ning siin on loodust ja privaatsust küllaga, mistõttu ma ise tihti suvilaga seiklema ei lähe. Seega tundus mõistlik suvilat ka teistega jagada."])},
  "about_body_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siis sündiski Jessy Rent OÜ, mida püüame tasapisi arendada ning edaspidi loodame rendiparki soetada veel ka peotelgi ja kümblustünni."])},
  "footer_follow_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jälgi meid"])},
  "footer_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leht"])},
  "footer_place_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäta oma meile tagasiside!"])},
  "review_form_label_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "review_form_label_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "review_form_label_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of rental"])},
  "review_form_label_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate Jessy Rent?"])},
  "review_form_placeholder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "review_form_placeholder_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was your experience?"])},
  "review_form_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit review"])},
  "review_form_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews will be verified before being published."])},
  "availability_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking availability"])}
}