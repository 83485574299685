<template>


    <div class="card is-flex has-background-white is-align-items-flex-end p-4 is-relative">
        <img :src="require(`@/assets/img/${card.image }`)" />
    <div class="is-relative info has-text-white">
        <p class="is-size-3">{{card.content}}</p>
        <p>{{card.desc_small}}</p>
        <!-- <button class="mt-2 has-background-white is-text-black is-clickable p-3 mt-2">Read More</button> -->
    </div>
</div>
</template>
<script>
export default {
  props: {
    card: Object
  },

  methods: {
    backgroundStyles(image) {
      return {
        // any other styles you might need to add on as an example
        'background-image': `url(${require(`@/assets/img/${image }`)})`,
      }
    }
  },
}
</script>
<style lang="scss">
  .card {
    height: 25rem;
    border-radius: 15px;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(black, 0.5);
    transition: 0.125s ease all;
    &:hover{
      transform: translateY(2px);
      transition: 0.125s ease all;
      cursor: pointer;
      &:before{
        opacity: 1;
      }
      .info{
        opacity: 1;
        transform: translateY(0px);
        width: 100%;
      }
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background: rgba(black, 0.6);
      z-index: 2;
      transition: 0.5s;
      opacity: 0;
    }
    img{
      &:hover{
              filter: brightness(50%);
      }
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 90%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
    }
    .info{
      z-index: 3;
      transition: 0.5s;
        @media (min-width: 1250px) {
          opacity: 0;
          transform: translateY(30px);

        }
        margin: 0px;
      p{
        letter-spacing: 1px;
        font-size: 15px;
        margin-top: 8px;
      }
      button{
        // padding: 0.6rem;
        outline: none;
        border: none;
        font-weight: bold;
        transition: 0.4;
      }
    }
  }
</style>