
<template>
  <router-view />
  <notifications classes="my-notification" />

</template>
<script>
import data from './data/data.json'
export default {
  name: "App",

  data() {
    return {
      payload: {
        email: "",
        password: "",
      },
            data: data
    };
  },
  methods: {
    getUser(){
      this.$store.commit('LOGOUT')
            }
  },
  mounted() {
      this.$i18n.locale = 'est';
    if (this.$store.state.isProgress) {
      this.$store.state.isProgress = false;
    }
    if(this.$store.state.user != null) {
      this.getUser()
    } else{
    this.$store.commit('LOGOUT')
    }
  }
};
</script>
<style lang="scss">
.my-notification {
  // styling
  margin: 10px 24px 0 0;
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  animation: popIn;
  animation-duration: 4.5s;
  background: #44a4fc;
  border-radius: 6px;

  &.success {
    background: #68cd86;
  }

  &.warn {
    background: #ffb648;
  }

  &.error {
    background: var(--danger);
  }

  @keyframes popIn {
    0% {
      margin-top: -5rem;
    }
    20% {
      margin-top: 10px;
    }
    80% {
      margin-top: 10px;
    }
    100% {
      margin-top: -5rem;
    }
  }
}
a.navbar-item:hover, a:hover{
  background-color: unset !important;
  color: rgb(172, 169, 169) !important;
}

a.navbar-item, a{
  color: white;
}
</style>

