<template>
  <div :class="icon? 'has-icons-left has-icons-right' : ''" class="control field">
      <label class=" has-text-weight-bold" v-if="label" :for="label">{{label}}</label>
      <input
      class="input mt-1 has-text-black"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :required="isRequired"
      :disabled="isDisabled"
      :class="vClass"
      :id="label ? label : null"
      style="z-index: 0"
      @click="(element) => scrollTo(element)"
      @input="$emit('update:modelValue', $event.target.value)"
      autocomplete="on"
      :min="min"
      :max="max"
      :step="step"
      />
    <span v-if="icon" class="icon is-small is-left">
      <img class="iconsvg" :src="icon" />
    </span>

    <span
      v-if="type == 'password' && passwordToggle"
      @click="(e) => (togglePassword(e), (passwordToggle = !passwordToggle))"
      class="is-right"
      style="
        position: absolute;
        z-index: 10;
        top: 0.2rem;
        right: 0.2rem;
        padding: 8px;
        cursor: pointer;
      "
    >
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="eyeIconTitle"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="miter"
        fill="none"
        color="#2329D6"
      >
        <title id="eyeIconTitle">Visible (eye)</title>
        <path
          d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
        />
        <circle cx="12" cy="12" r="3" />
      </svg>
    </span>

    <span
      v-if="type == 'password' && !passwordToggle"
      @click="(e) => (togglePassword(e), (passwordToggle = !passwordToggle))"
      class="is-right"
      style="
        position: absolute;
        z-index: 10;
        top: 0.2rem;
        right: 0.2rem;
        padding: 8px;
        cursor: pointer;
      "
    >
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="eyeCrossedIconTitle"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="miter"
        fill="none"
        color="#2329D6"
      >
        <title id="eyeCrossedIconTitle">Hidden (crossed eye)</title>
        <path
          d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
        />
        <circle cx="12" cy="12" r="3" />
        <path d="M3 21L20 4" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: {
    placeholder: String,
    icon: String,
    type: String,
    isRequired: Boolean,
    modelValue: String,
    isDisabled: Boolean,
    vClass: String,
    onChangeEmit: String,
    min: Number,
    max: Number,
    step: Number,
    label: String
  },
  data() {
    return {
      passwordToggle: false,
      iconSvg: "../../assets/img/" + this.icon,
    };
  },
  methods: {
    togglePassword(e) {
      if (e.target.closest("div").firstChild.type === "text") {
        e.target.closest("div").firstChild.type = "password";
      } else {
        e.target.closest("div").firstChild.type = "text";
      }
    },

    scrollTo(element) {
      setTimeout(() => {
        element.target.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 140);
    },
  },
};
</script>
<style lang="scss">
.iconsvg {
  width: 18px;
  height: 18px;
}
input.input::placeholder,
input.input:disabled::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-darker);
  opacity: 1; /* Firefox */
}

input.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray-darker);
}

input.input::-ms-input-placeholder,
input.inputt:disabled:-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gray-darker);
}

input.input:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--gray-darker);
}

input.input:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--gray-darker);
}
</style>